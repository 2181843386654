<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('audio.edit-audio') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-5">
            <b-overlay :show="showOverlay">
             <b-form @submit.prevent="onSubmit" autocomplete="off">
                <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('general.title')" label-for="title">
                <!-- Naslov je dosta specifičan. Ako je u pitanju latinično pismo na srpskom, onda bi pored naslova -->
                <!-- trebalo da se pojavi ikonica za LAT tag. -->
                <template v-if="$root.lang.IANA === 'sr-Latn' || $root.lang.IANA === 'sr-Cyrl'">
                  <b-row>
                    <b-col md="11">
                      <b-form-input	id="title" :state="titleState" v-model="media.title" :placeholder="$t('general.enterTitle')" autocomplete="new-password" trim></b-form-input>
                      <b-form-invalid-feedback>{{	$t("general.enter-valid-title")	}}</b-form-invalid-feedback>
                    </b-col>
                    <b-col md="1" class="col-form-label">
                      <span id="latIcon" @click="insertLat('title')">LAT</span>
                    </b-col>
                  </b-row>
                </template>
                <template v-else>
                  <b-row>
                    <b-col md="12">
                      <b-form-input	id="title" :state="titleState" v-model="media.title" :placeholder="$t('videos.enter-title')" autocomplete="new-password" trim></b-form-input>
                      <b-form-invalid-feedback>{{	$t("general.enter-valid-title")	}}</b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                </template>
                </b-form-group>
                <div v-if="media.status < 4">
                   <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('audio.previewAudio')" label-for="lowRes">
                    <p>{{ $t('audio.transcoding') }}</p>
                    <b-progress max="100" height="2rem" variant="primary">
                      <b-progress-bar :value="transcodeProgress">
                        <strong>{{ transcodeProgress }} %</strong>
                      </b-progress-bar>
                    </b-progress>
                  </b-form-group>
                </div>
                <div v-else>
                  <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('audio.previewAudio')">
                      <audio controls preload="none" oncontextmenu="return false;" :src="media.lowResUrl">
                          <source :src="media.lowResUrl" :type="media.mimeType">
                      </audio>
                  </b-form-group>
                  <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('audio.originalAudio')">
                      <audio controls preload="none" oncontextmenu="return false;" :src="media.lowResUrl">
                          <source :src="media.fullResUrl" :type="media.mimeType">
                      </audio>
                  </b-form-group>
                </div>

                <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('general.description')" label-for="description">
                    <editor id="description" :init="tinyInitAudioEditDesc" v-model="media.description"></editor>
                    <b-form-invalid-feedback :state="descriptionState">{{ $t('general.enterDescription') }}</b-form-invalid-feedback>
                    <small v-if="$root.lang.IANA === 'sr-Latn'">Napomena: ukoliko unosite reči na stranim jezicima (latiničnim) označite ih i kliknite ikonicu LAT, kako bi se ispravno prikazale na ćiriličnoj verziji.</small>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('general.titleEn')" label-for="title">
                  <b-form-input id="titleEn" v-model="media.titleEn" :placeholder="$t('general.enterTitleEn')" autocomplete="new-password"></b-form-input>
                  <b-form-invalid-feedback :state="titleEnState">{{ $t('general.enterTitleEnError') }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('general.descriptionEn')" label-for="description">
                  <editor id="descriptionEn" :init="tinyInitAudioEditDescEn" v-model="media.descriptionEn"></editor>
                  <b-form-invalid-feedback :state="descriptionEnState">{{ $t('general.descriptionEnError') }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('general.author')" label-for="author">
                    <b-form-input id="author" v-model="media.author" :placeholder="$t('general.enter-author')" trim></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('audio.year')" label-for="year">
                  <b-form-input id="year" v-model="media.year" :placeholder="$t('audio.enter-year')" trim></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('general.categories')">
                  <TagInput :state="categoriesState" :model="selectedCats" :options="avaiableCategoryList" :type="$t('general.category')" @input="selectedCats=$event" />
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('general.languages')">
                  <TagInput :state="languagesState" :model="selectedLangs" :options="avaiableLanguageList" :type="$t('general.language')" @input="selectedLangs=$event" :error="$t('general.please-select-language')"/>
                </b-form-group>

                <b-form-group v-if="admin.role.name=='SuperAdministrator'" label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('general.approve')">
                    <b-form-checkbox v-model="appruve" switch></b-form-checkbox>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('general.published')">
                    <b-form-checkbox v-model="media.published" switch></b-form-checkbox>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4">
                  <b-row>
                    <b-col class="d-flex justify-content-between">
                      <template v-if="!media.deleted">
                        <b-overlay :show="saveSpinner" spinner-small spinner-variant="secondary" opacity="0.5">
                            <b-button :disable="saveSpinner" type="submit" variant="primary">{{ $t('general.save-changes')}}</b-button>
                        </b-overlay>
                          <b-overlay :show="saveSpinner" spinner-small spinner-variant="secondary" opacity="0.5">
                              <b-button :disable="saveSpinner" type="button" @click="deleteAudio" variant="outline-danger">{{ $t('audio.delete-audio')}}</b-button>
                          </b-overlay>
                      </template>
                      <template v-else>
                        <b-overlay :show="saveSpinner" spinner-small spinner-variant="secondary" opacity="0.5">
                            <b-button :disable="saveSpinner" type="button" @click="restoreAudio" variant="secondary">{{ $t('audio.restore-audio')}}</b-button>
                        </b-overlay>
                        
                        <b-overlay :show="saveSpinner" spinner-small spinner-variant="secondary" opacity="0.5">
                            <b-button :disable="saveSpinner" type="button" @click="deleteAudioForever" variant="outline-danger">{{ $t('audio.delete-audio-forever')}}</b-button>
                        </b-overlay>
                      </template> 
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-form>
            </b-overlay>
        </b-col>
      </b-row>
    </b-container>

    <!-- Modal za brisanje-->
    <b-overlay :show="busy" no-wrap @shown="confirmDialog" @hidden="onHidden">
			<template #overlay>
				<div class="row">
					<b-col cols="12" ref="dialog"	tabindex="-1"	role="dialog"	aria-modal="false"	aria-labelledby="form-confirm-label"	class="bg-white align-self-center"	style="min-width: 500px;border: 2px solid #9ADCDA; border-radius: 8px; text-align: center;">
						<div class="p-4 mt-4">
              <div class="mb-3"><strong class="mb-3" id="form-confirm-label">{{$t("general.are-you-sure") }}</strong></div>
							<div class="mb-5 text-left">
                {{ $t("audio.delete-audio-forever-question") }}
							</div>
							<div class="mb-4">
								<b-button	variant="outline-primary"	class="mr-3"	@click="onCancel">{{ $t("general.no") }}</b-button>
								<b-button variant="outline-danger" @click="onOK">{{$t("general.yes")}}</b-button>
							</div>
						</div>
					</b-col>
				</div>
			</template>
		</b-overlay>

  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import editor from '@tinymce/tinymce-vue'
import { BFormCheckbox, BProgress, BProgressBar } from 'bootstrap-vue'
import TagInput from "@/components/admin/_utils_/TagInput.vue"
export default {
  name: "EditAudio",
  components: { NavBar, editor, TagInput, BFormCheckbox, BProgress, BProgressBar },
  data() {
    return {
      admin: {},
      showOverlay: true,
      media: {},
      appruve: false,
      mediaUser: {},
      selectedCats: [],
      avaiableCategoryList: [],
      tinyInitAudioEditDesc: this.tinyInit(300),
      tinyInitAudioEditDescEn: this.tinyInit(300),
      avaiableLanguageList: ['Serbian', 'English', 'German', 'Slovak', 'Hungarian', 'Bulgarian', 'Romanian', 'Ukranian', 'Moldovan', 'Rusyn', 'Roma', "Other"],
      selectedLangs: [],
      saveSpinner: false,
      /* State varijable  */
      titleState: null,
      mediaFileState: null,
      descriptionState: null,
      titleEnState: null,
      descriptionEnState: null,
      authorState: null,
      yearState: null,
      categoriesState: null,
      languagesState: null,
      acceptedTermsState: null,
      /* Progres transkodiranja */
      transcodeProgress: 0.00,
      transcodeProgresTimer: null,
      busy: false,
      restoreSpinner: false
    }
  },
  
  computed: {
    _lang: function() {
      return this.$root.lang
    }
  },

  async created() {
    this.admin = this.$root.user;

    this.displayTags();
    // Get Media
    await this.$http.get('/cms/api/media/get/' +  this.$route.params.audioId + "/", { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.media = r.data;
      // console.log(r.data);
      this.appruve = !this.media.pending;
      this.selectedCats = this.extractTags(r.data.categories);
      if (r.data.languages) {
        this.selectedLangs = r.data.languages.split(",");
      }
      if (this.media.status < 4) {
        this.getTranscodeProgress(this.media);
      }
      this.approve = !this.media.pending;
    }).catch(e => {
        this.handleError(e);
    })
    this.showOverlay = false;
  },
  methods: {
    onSubmit: function(e) {
        e.preventDefault();
        this.saveSpinner = true;
        // console.log("Edit audio!");
        this.media.year = parseInt(this.media.year);
        // console.log(this.selectedCats);
        this.media.categories = this.findRealTags(this.selectedCats);
        if (this.selectedLangs.length > 0) {
            this.media.languages = this.selectedLangs.sort().join(",")
        } else {
            this.media.languages = null;
        }

        if (this.admin.role.name === "SuperAdministrator") {
            this.media.pending = !this.appruve;
        }
        
        // console.log(this.media);
        if (this.validateForm()) {
            this.$http.post("/cms/api/media/edit", this.media, {headers: {Authorization: this.getAuthData().accessToken}})
            .then(r => {
                this.$router.go(-1);
            }).catch(e => { this.handleError(e); })
        }
        this.saveSpinner = false;
    },

    /* Traži u listi tagova one elemente koji se nalaze u listi izabranih kategorija */
    findRealTags(tagList) {
        return this.categoryTagObjectsList.filter(function (item) {
            for (var i = 0; i < item.names.length; i++) {
              if (tagList.includes(item.names[i].name)) {
                return item;
              }
            }
          })
    },

    extractTags(categoryList) {
      var adminLang = this.$root.lang.shortName;
      var myCategoriesSetring = [];
      categoryList.filter(function(item) {
          for (var i = 0; i < item.names.length; i++) {
            if (item.names[i].language.shortName === adminLang) {
              myCategoriesSetring.push(item.names[i].name);
            }
          }
          });
      return myCategoriesSetring;
    },

    deleteAudio() {
      this.$http.post('/cms/api/media/delete/' +  this.media.id + "/", { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
          if (r.data === "OK") {
          this.$router.go(-1);
          }
      }).catch(e => {
          var message = this.handleError(e);
      })
    },

    deleteAudioForever() {
      this.busy = true;
    },

    restoreAudio() {
      this.restoreSpinner = true;
      this.$http.post('/cms/api/media/restore-media/' +  this.media.id + "/", { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
         if (r.data === "OK") {
          this.restoreSpinner = false;
          this.$router.go(-1);
         }
      }).catch(e => {
        this.handleError(e);
      }) 
    },

    validateForm() {
        if (typeof this.media.title === "undefined" || this.media.title.length === 0) {
            this.titleState = false;
            document.getElementById("title").scrollIntoView({ block: 'center',  behavior: 'smooth' });
            return false;
        } else {
            this.titleState = null;
        }
        
        if (typeof this.media.description === "undefined" || this.media.description.length === 0) {
            this.descriptionState = false;
            document.getElementById("description").nextSibling.classList.add("is-invalid");
            document.getElementById("description").parentNode.scrollIntoView({ block: 'center',  behavior: 'smooth' });
            return false;
        } else {
            document.getElementById("description").nextSibling.classList.remove("is-invalid");
            this.descriptionState = null;
        }
        
        if (typeof this.media.titleEn === "undefined" || this.media.titleEn.length === 0) {
            this.titleEnState = false;
            document.getElementById("titleEn").scrollIntoView({ block: 'center',  behavior: 'smooth' });
            return false;
        } else {
            this.titleEnState = null;
        }

        if (typeof this.media.descriptionEn === "undefined" || this.media.descriptionEn.length === 0) {
            this.descriptionEnState = false;
            document.getElementById("descriptionEn").nextSibling.classList.add("is-invalid");
            document.getElementById("descriptionEn").parentNode.scrollIntoView({ block: 'center',  behavior: 'smooth' });
            return false;
        } else {
            document.getElementById("descriptionEn").nextSibling.classList.remove("is-invalid");
            this.descriptionEnState = null;
        }

        if (typeof this.media.author === "undefined" || this.media.author.length === 0) {
            this.authorState = false;
            document.getElementById("author").scrollIntoView({ block: 'center',  behavior: 'smooth' });
            return false;
        } else {
            this.authorState = null;
        }

        if (typeof this.media.year === "undefined" || parseInt(this.media.year) === "NaN") {
            this.yearState = false;
            document.getElementById("year").scrollIntoView({ block: 'center',  behavior: 'smooth' });
            return false;
        } else {
            this.yearState = null;
        }
    
        if (this.selectedLangs === null || this.selectedLangs.length === 0) {
            this.languagesState = false;
            document.getElementById("langauges").scrollIntoView({ block: 'center',  behavior: 'smooth' });
            return false;
        } else {
            this.languagesState = null;
        }

        if (this.selectedCats.length === 0) {
            document.getElementById("categories").scrollIntoView({ block: 'center',  behavior: 'smooth' });
            this.categoriesState = false;
            return false;
        } else {
            this.categoriesState = null;
        }

        if (this.acceptedTerms === "not_accepted") {
            document.getElementById("acceptedTerms").scrollIntoView({ block: 'center',  behavior: 'smooth' });
            this.acceptedTermsState = false;
            return false;
        } else {
            this.acceptedTermsState = null;
        }
        return true;
    },

    // Modal 
		confirmDialog: function() {
			this.$refs.dialog.focus();
		},
		onHidden: function() {
			// this.$refs.submit.focus();
		},
		onOK: function() {
      this.$http.post('/cms/api/media/delete-forever/' +  this.media.id + "/", { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
         if (r.data === "OK") {
          this.busy = false;
          this.$router.go(-1);
         }
      }).catch(e => {
        this.handleError(e);
      })      
		},
		onCancel: function() {
			this.busy = false;
		},

    getTranscodeProgress(m) {
      // console.log("Media status je: " + m.status);
      if (m.status < 4)  {
        this.transcodeProgresTimer = null;
        // console.log("Procenat je:" + this.transcodeProgress);
        if (this.transcodeProgress < 100) {
          this.$http.post("/cms/api/media/transcode-progress/" + m.id + "/", { headers: {Authorization: this.getAuthData().accessToken}})
            .then(r => { 
              this.transcodeProgress = r.data.toFixed(2);
            }).catch(e => {
              clearTimeout(this.transcodeProgresTimer);
              this.handleError(e);
          })
          // console.log("Postavljam tajmer!");
          this.transcodeProgresTimer = setTimeout(function () { this.getTranscodeProgress(m) }.bind(this), 500)
        } else {
          clearTimeout(this.transcodeProgresTimer);
          this.transcodeProgress = 100;
          this.media.status = 4;
        }  
      } else {
        console.log("Video je već transkodiran ili je u fazi transkodiranja!");
      }
    },

    async displayTags() {
      // Get all tags
      await this.$http.get("/cms/api/tag/all-published/" + this.$root.lang.shortName, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
          this.categoryTagObjectsList = r.data
          var myOptions = [];
          if (r.data.length > 0) {
              for (var i = 0; i < r.data.length; i++) {
                  var tag = r.data[i];
                  if (tag.names.length > 0) {
                  for (var j = 0; j < tag.names.length; j++) {
                      var names = tag.names[j];
                      if (names.language.shortName === this.$root.lang.shortName) {
                          myOptions.push(names.name);
                      }
                  }
              }
          }
          this.avaiableCategoryList = myOptions;
      }
      }).catch(e => { this.handleError(e); });
      const myCategories = this.findRealTags(this.selectedCats);
      this.selectedCats = this.extractTags(myCategories);
    }
  },

  watch: {
    selectedCats() {
      if (this.selectedCats.length > 0) {
        this.categoriesState = null;
      }
    },
    selectedLangs() {
      if (this.selectedLangs.length > 0) {
        this.languagesState = null;
      }
    },
    _lang : function() {
      // console.log("Promenjen jezik");
      this.displayTags();
    }
  }
}
</script>

<style scoped>
/* Audio */
audio {    
  width: 100%;
  height: 130%;
  border: 2px solid #D9DBE1;
  border-radius: 8px;
  padding: 0px 5px;
}
audio:focus {border: 2px solid #35C0C0 !important;}
</style>
